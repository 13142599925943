import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import ButtonLT from '../utils/elements/ButtonLT';
import Link from '../Link';
import styles from './shoppingCart.module.css';
import { humanize } from '../../lib/utils';

const ShoppingCartMenu = ({ id, className }) => {
  const { data: dataCarrito, loading: loadingCarrito } = useSelector(
    (state) => state.carrito,
  );
  const { data: dataBox } = useSelector((state) => state.box);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const total = dataCarrito.items.reduce(
    (accum, current) => accum + current.precioVenta,
    0,
  );
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className="p-0 max-h-12">
      <IconButton
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleOpen}
        id={id}
        className={`${styles.iconButton} ${className}`}
        data-testid="icon-carrito"
      >
        {!loadingCarrito && (
          <span
            className={`
              absolute inline-flex items-center justify-center
              px-2 py-1
              text-xs font-bold leading-none text-white
              bg-primary rounded-full
              translate-x-3.5 -translate-y-3.5
            `}
            data-testid="badge-carrito"
          >
            {dataCarrito.items.length
              + (dataBox && dataBox.items.length > 0 ? dataBox.items.length : 0)}
          </span>
        )}
        <ShoppingCartIcon />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-end"
        transition
        disablePortal
        className={styles.positionList}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper id={styles.menuList}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  {dataCarrito.items.map((item) => (
                    <MenuItem key={item._id}>
                      <Card className={styles.card} key={item._id}>
                        <Grid container justifyContent="space-between">
                          <Grid item xs={3}>
                            <CardMedia
                              className={styles.media}
                              image={item.imagenes[0].secureUrl}
                              title={item.descripcionVenta.substr(0, 10)}
                            />
                          </Grid>
                          <Grid item xs={8}>
                            <Typography
                              variant="body1"
                              color="textSecondary"
                              component="p"
                              noWrap
                            >
                              {item.categoria}
                            </Typography>
                            <Typography
                              gutterBottom
                              variant="body1"
                              color="textSecondary"
                              component="p"
                            >
                              S./
                              {' '}
                              {item.precioVenta}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Card>
                    </MenuItem>
                  ))}
                  {dataBox && dataBox.items.length > 0 && (
                    <MenuItem>
                      <Typography
                        variant="h6"
                        color="textSecondary"
                        component="p"
                      >
                        Box
                      </Typography>
                    </MenuItem>
                  )}
                  {dataBox
                    && dataBox.items.map((item) => (
                      <MenuItem key={item._id}>
                        <Card className={styles.card} key={item._id}>
                          <Grid container justifyContent="space-between">
                            <Grid item xs={3}>
                              <CardMedia
                                className={styles.media}
                                image={item.imagenes[0].secureUrl}
                                title={item.descripcionVenta.substr(0, 10)}
                              />
                            </Grid>
                            <Grid item xs={8}>
                              <Typography
                                variant="body1"
                                color="textSecondary"
                                component="p"
                                noWrap
                              >
                                {humanize(item.categoria)}
                              </Typography>
                              <Typography
                                gutterBottom
                                variant="body1"
                                color="textSecondary"
                                component="p"
                              >
                                S./
                                {' '}
                                {item.precioVenta}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Card>
                      </MenuItem>
                    ))}
                  <MenuItem className={styles.button}>
                    {dataCarrito.items.length === 0
                    && dataBox
                    && dataBox.items.length === 0 ? (
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        component="p"
                        noWrap
                      >
                        CARRITO VACÍO
                      </Typography>
                      ) : (
                        <Grid
                          container
                          alignItems="center"
                          className={styles.itemFixed}
                        >
                          <Grid item xs={6}>
                            <ButtonLT style={{ width: '100%' }}>
                              <Link href="/checkout/prendas/" track={false}>
                                Ver Carrito
                              </Link>
                            </ButtonLT>
                          </Grid>
                          {total !== 0 ? (
                            <Grid item xs={6}>
                              <Typography
                                variant="body1"
                                component="p"
                                color="textSecondary"
                              >
                                TOTAL: S./
                                {' '}
                                {total}
                              </Typography>
                            </Grid>
                          ) : null}
                        </Grid>
                      )}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

ShoppingCartMenu.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
};

ShoppingCartMenu.defaultProps = {
  className: null,
};

export default ShoppingCartMenu;
